import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { motion } from 'framer-motion'
import styles from './Menu.styles.scss'
import variants from './Menu.motion'

const Menu = ({ isOpen, nav, onNavigate }) => {
  const handleNavLinkClick = (event, path) => {
    event.preventDefault()
    onNavigate()
    if (path) {
      setTimeout(() => {
        navigate(path)
      }, 200)
    }
  }

  return (
    <div className={styles.Menu}>
      <div className={styles.container}>
        <motion.div
          className={styles.bg}
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          variants={variants.bg}
        />
        <motion.div
          className={styles.content}
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
          variants={variants.content}
          style={
            !isOpen ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }
          }
        >
          <ul className={styles.nav}>
            {nav.map((item) => (
              <li key={item.id} className={styles.nav__item}>
                <a
                  href={item.url}
                  onClick={(event) => handleNavLinkClick(event, item.url)}
                  className={styles.nav__link}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </motion.div>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  isOpen: false,
  nav: [],
  onNavigate: () => {},
}

Menu.propTypes = {
  isOpen: PropTypes.bool,
  nav: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  onNavigate: PropTypes.func,
}

export { Menu }
