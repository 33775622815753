import React from 'react'
import PropTypes from 'prop-types'
import '../styles/main.scss'
import { Metadata } from '../components/Metadata'
import { PageTransition } from '../components/PageTransition'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { useViewportHeight } from '../hooks/useViewportHeight'
import styles from './Layout.styles.scss'
import favicon from '../images/favicon.png'
import faviconDarkmode from '../images/favicon-darkmode.png'

const Layout = ({ site, page, children }) => {
  useViewportHeight()

  return (
    <>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={{ default: favicon, darkMode: faviconDarkmode }}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />

      <PageTransition path={page.path}>
        <div className={styles.el} data-darkmode={page.isDarkMode}>
          <Header
            nav={site.header.nav}
            isDarkMode={page.hasDarkModeHeader || page.isDarkMode}
            path={page.path}
            title={site.metadata.title}
          />
          <main className={styles.main}>{children}</main>
          <Footer {...site.footer} isDarkMode={page.isDarkMode} />
        </div>
      </PageTransition>
    </>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    favicon: undefined,
    header: {},
    footer: {},
  },
  page: {
    title: undefined,
    image: undefined,
    path: undefined,
    hasDarkModeHeader: false,
    isDarkMode: false,
  },
}

Layout.propTypes = {
  site: PropTypes.shape({
    metadata: PropTypes.object,
    favicon: PropTypes.string,
    header: PropTypes.object,
    footer: PropTypes.object,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    path: PropTypes.string,
    hasDarkModeHeader: PropTypes.bool,
    isDarkMode: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
}

export default Layout
