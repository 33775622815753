const variants = {
  bg: {
    hidden: {
      opacity: 0,
      transition: { duration: 0 },
    },
    visible: {
      opacity: 1,
      transition: { duration: 0 },
    },
  },
  content: {
    hidden: {
      opacity: 0,
      transition: { duration: 0 },
    },
    visible: {
      opacity: 1,
      transition: { duration: 1, ease: 'easeOut', delay: 0.1 },
    },
  },
}
export default variants
