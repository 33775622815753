import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { Container } from '../Container'
import { Logo } from '../Logo'
import { RichText } from '../RichText'
import styles from './Footer.styles.scss'

const Footer = ({ column01, column02, isDarkMode }) => {
  const [isVisible, setIsVisible] = useState(false)
  const handleOnEnter = () => setIsVisible(true)

  return (
    <Waypoint onEnter={handleOnEnter}>
      <footer className={styles.el}>
        <Container>
          <div className={styles.inner}>
            <div className={styles.head}>
              <div className={styles.logo}>
                <Logo
                  isVisible={isVisible}
                  isInverse={isDarkMode}
                  isAnimated={false}
                />
              </div>
            </div>
            <div className={styles.column}>
              <RichText>
                {column01 && column01}
                <p>Copyright {new Date().getFullYear()}</p>
              </RichText>
            </div>
            <div className={styles.column}>
              {column02 && <RichText>{column02}</RichText>}
            </div>
          </div>
        </Container>
      </footer>
    </Waypoint>
  )
}

Footer.defaultProps = {
  column01: undefined,
  column02: undefined,
  isDarkMode: false,
}

Footer.propTypes = {
  column01: PropTypes.node,
  column02: PropTypes.node,
  isDarkMode: PropTypes.bool,
}

export { Footer }
