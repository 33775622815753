/**
 * Link Resolver for Prismic content
 *
 * @param {object} page
 */

exports.linkResolver = (page) => {
  if (page.uid === 'home') {
    return `/`
  }
  if (page.type === 'blog_post') {
    return `/blog/${page.uid}`
  }

  // fallback route
  return `/${page.uid}`
}
