import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { useScroll } from 'react-use-gesture'
import { Container } from '../Container'
import { Menu } from '../Menu'
import { Link } from '../Link'
import { BtnMenu } from '../BtnMenu'
import styles from './Header.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const Header = ({ isDarkMode, nav, path, title }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const panelRef = useRef()
  const isMobile = useMedia({ maxWidth: '768px' })
  const minScroll = 20 // Math.max(windowSize.height, 1000)

  const handleToggleMenu = () => setMenuIsOpen(!menuIsOpen)
  const handleMenuNavigate = () => setMenuIsOpen(false)

  const handleScroll = (event) => {
    const { xy } = event
    setIsSticky(xy[1] > minScroll)
    // setIsSticky(xy[1] > minScroll && movement[1] < -10)
    // setIsSticky(xy[1] > minScroll && direction[1] === -1)
  }

  const bind = useScroll((state) => handleScroll(state), {
    domTarget: IS_BROWSER ? window : undefined,
  })

  return (
    <>
      <Menu isOpen={menuIsOpen} nav={nav} onNavigate={handleMenuNavigate} />
      <header
        className={styles.el}
        data-darkmode={isDarkMode || menuIsOpen}
        {...bind()}
      >
        <div className={styles.panel} ref={panelRef} data-shadow={isSticky}>
          <Container>
            <div className={styles.inner}>
              <h1 className={styles.logo}>
                <Link to="/" aria-label={title}>
                  {title}
                </Link>
              </h1>
              {!isMobile && (
                <div className={styles.nav}>
                  <ul className={styles.nav__list}>
                    {nav.map((item) => {
                      const isActive = path === item.url
                      return (
                        <li key={item.id} className={styles.nav__item}>
                          <Link
                            to={item.url}
                            target={item.target}
                            className={styles.nav__link}
                            data-active={isActive}
                          >
                            {item.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              {isMobile && (
                <div className={styles.btnMenu}>
                  <BtnMenu isOpen={menuIsOpen} onClick={handleToggleMenu} />
                </div>
              )}
            </div>
          </Container>
        </div>
      </header>
    </>
  )
}

Header.defaultProps = {
  isDarkMode: false,
  nav: [],
  path: undefined,
  title: undefined,
}

Header.propTypes = {
  isDarkMode: PropTypes.bool,
  nav: PropTypes.array,
  path: PropTypes.string,
  title: PropTypes.string,
}

export { Header }
