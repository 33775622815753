import { useStaticQuery, graphql } from 'gatsby'
import { get } from '../../utils/get'
import { getRichText } from './getRichText'
import { getLink } from './getLink'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const getSiteConfig = () => {
  const configData = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        prismicConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            nav {
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
            footer_column_01 {
              raw
            }
            footer_column_02 {
              raw
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = configData.gatsbyConfig.siteMetadata || {}
  const prismicConfig = configData.prismicConfig.data || {}

  const nav = []
  if (prismicConfig.nav) {
    prismicConfig.nav.forEach((item, index) => {
      nav.push(getLink(item.link, item.title, `nav_${index}`))
    })
  }

  return {
    metadata: {
      title: prismicConfig.title || gatsbyConfig.title,
      description: prismicConfig.meta_description || gatsbyConfig.description,
      subtitle: prismicConfig.meta_subtitle || gatsbyConfig.subtitle,
      image: get(prismicConfig, 'meta_image.url') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    header: {
      nav,
    },
    footer: {
      column01: getRichText(prismicConfig, 'footer_column_01.raw'),
      column02: getRichText(prismicConfig, 'footer_column_02.raw'),
    },
  }
}
